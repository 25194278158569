body {
  background-color: #121212;
  color: silver;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  padding: 20px;
}

.app {
  max-width: 600px;
  margin: auto;
  text-align: center;
}

input, button {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
}

input {
  width: 70%;
  margin-right: 10px;
  color: black;
}

button {
  background-color: #1a73e8;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0c47a1;
}

.output-area {
  position: relative;
  border-radius: 8px;
  background-color: #222; 
  margin-top: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px; /* padding eşleştirildi */
  background-color: #333; /* Header arka plan rengi eşleştirildi */
  width: calc(100% - 20px); /* Border eklenince genişlik ayarlaması */
}

.copy-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.copy-button img {
  width: 16px; /* İkonun genişliği */
  height: 16px; /* İkonun yüksekliği */
  filter: brightness(0) invert(1);
}

.output {
  width: 100%;
  color: silver;
  padding: 10px 10px;
  font-family: monospace;
  resize: none;
  border: none;
  margin: 0;
  box-sizing: border-box; /* Padding ve border dahil genişliği sabit tutar */
  background-color: #222;
}
.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input, .textarea {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  width: 70%;
  color: black; /* Text color for visibility */
  background-color: white; /* Background color to match inputs */
  margin-right: 10px; /* Space between textarea and button */
}

button {
  background-color: #1a73e8;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0c47a1;
}
.featured-accounts {
  text-align: center;
  margin-top: 50px;
}

.featured-accounts h2 {
  color: silver; /* Adjust color as needed */
  margin-bottom: 10px;
}

.profiles {
  display: flex;
  justify-content: center; 
  align-items: center;      
  flex-wrap: wrap;         
  gap: 10px;          
  margin-top: 20px;     
}


.profile-pic {
  width: 60px; /* Set the size of the images */
  height: 60px; /* Maintain aspect ratio for circle */
  border-radius: 50%; /* Makes images circular */
  margin: 0 10px; /* Space between images */
  object-fit: cover; /* Ensures the images are nicely cropped */
}
.get-featured-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1a73e8;  
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px; 
  align-self: center; 
}

.get-featured-button:hover {
  background-color: #0c47a1;  
}